import * as React from "react";

// MUI
import { Divider, Typography } from "@material-ui/core";

// Charts
import {
	Line,
	LineChart,
	ReferenceLine,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";

// Util
import { orderBy } from "lodash";
import moment from "moment";
import { GlassPaper } from "../GlassPaper/GlassPaper";

interface ITooltipPayload {
	color: string;
	dataKey: string;
	fill: string;
	formatter: any;
	name: string;
	payload: any;
	stroke: string;
	strokeWidth: number;
	type: any;
	unit: any;
	value: number;
}

interface ICustomTooltip {
	active: boolean;
	payload: ITooltipPayload[];
	label: string;
}

// Custom Tooltip
const CustomTooltip: any = ({ active, payload, label }: ICustomTooltip) => {
	if (active && payload && payload.length) {
		// Get label and convert to time if timestamp
		let label = payload[0].name;
		if (payload[0].payload?.timestamp) {
			label = moment(payload[0].payload?.timestamp).format(
				"MMMM Do YYYY, h:mm a"
			);
		}

		// Get value and round if available
		let value = payload[0].value;
		if (typeof value === "number") {
			value = Math.round(value);
		}

		return (
			<GlassPaper square style={{ padding: "1rem" }}>
				<Typography variant="body2" align="center">
					{label}
				</Typography>
				<Divider style={{ width: "100%" }} />
				<Typography variant="body1" align="center">
					{value}
				</Typography>
			</GlassPaper>
		);
	}

	return null;
};

// Convenience Functions
const getAverage = (array: IHistoryData["data"]) => {
	// If data comes throught
	if (Boolean(array.length)) {
		let ave = array.reduce(
			(a, b) => {
				a.value = a.value + b.value;
				return a;
			},
			{ value: 0 }
		);

		return ave.value / array.length;
	} else return 0;
};
const everyNth = (arr: any[], nth: number) =>
	arr?.filter((e: any, i: any) => i % nth === nth - 1) || [];

// Interfaces
interface IHistoryData {
	endTime: string;
	id: string;
	name: string;
	size: number;
	startTime: string;
	timezone: string;
	data: { value: number; timestamp: number }[];
}

interface IHistoryLineChart {
	historyData: IHistoryData;
	showAverage?: boolean;
	sampleNth?: number;
	height?: number;
	width?: number;
	margins?: {
		top?: number;
		right?: number;
		left?: number;
		bottom?: number;
	};
	showTooltip?: boolean;
}

// Component
export const HistoryLineChart = ({
	historyData,
	showAverage = true,
	sampleNth = 10,
	height = 275,
	width = 450,
	margins = {
		top: 5,
		right: 30,
		left: 0,
		bottom: 5,
	},
	showTooltip = true,
}: IHistoryLineChart) => {
	// Destructure
	const { top, right, left, bottom } = margins;

	// null if no data available
	if (!historyData || !historyData?.data?.length) return null;

	// Handle Data Sample
	let data = orderBy(everyNth(historyData.data, sampleNth), "timestamp");

	// Handle Average Line
	let average = 0;
	if (showAverage) {
		average = getAverage(data);
	}

	// Render
	return (
		<>
			{/* Title */}
			<Typography
				variant="h6"
				style={{ textAlign: "center", paddingBottom: "1rem" }}
			>
				{historyData.name || ""}
			</Typography>

			{/* Chart Wrapper */}
			<LineChart
				width={width}
				height={height}
				data={data}
				margin={{
					top,
					right,
					left,
					bottom,
				}}
			>
				{/* Axis Data */}
				<XAxis
					name="Time"
					dataKey="timestamp"
					type="number"
					domain={["dataMin", "dataMax"]}
					tickFormatter={(timestamp: number) =>
						moment(timestamp).format("ddd, hA")
					}
					interval="preserveStartEnd"
				/>
				<YAxis
					name="Value"
					dataKey="value"
					domain={["auto", "auto"]}
					interval="preserveStartEnd"
				/>

				{/* Data */}
				<Line
					name="Value"
					dataKey="value"
					type={"step"}
					stroke="#8884d8"
					dot={false}
					label={false}
				/>

				{/* Tooltip */}
				{Boolean(showTooltip) && <Tooltip content={<CustomTooltip />} />}

				{/* Average Reference LIne */}
				{showAverage && (
					<ReferenceLine
						y={average}
						stroke="green"
						label={
							typeof average === "number" ? `Ave: ${Math.round(average)}` : ""
						}
					/>
				)}
			</LineChart>
		</>
	);
};
