import React from "react";

// Styles
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

// MUI
import { AppBar, Toolbar, Fab, SvgIcon } from "@material-ui/core";

// Icons
import { ReactComponent as Access } from "../../../static/svg/access.svg";
import { ReactComponent as Air } from "../../../static/svg/air.svg";
import { ReactComponent as Climate } from "../../../static/svg/climate.svg";
import { ReactComponent as Schedule } from "../../../static/svg/schedule.svg";
import { ReactComponent as Settings } from "../../../static/svg/settings.svg";
import { ReactComponent as Lighting } from "../../../static/svg/lighting.svg";

import { GlassPaper } from "../../GlassPaper/GlassPaper";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		appBar: {
			top: "auto",
			bottom: 0,
		},
		toolbar: {
			display: "flex",
			justifyContent: "space-evenly",
			padding: "1rem",
		},
		fabButton: {
			margin: "0 auto",
		},
	})
);

export const SpaceAppBarBottom: any = ({
	space,
	access,
	blinds,
	iaq,
	lights,
	schedule,
	thermostat,
}: any) => {
	const classes = useStyles();

	const history = useHistory();
	const { pathname, search } = history?.location;

	return (
		<AppBar position="fixed" color="transparent" className={classes.appBar}>
			<GlassPaper
				square
				style={{ position: "fixed", width: "100%", bottom: 90 }}
			>
				<Toolbar className={classes.toolbar}>
					{Boolean(access) && (
						<Fab
							color={search.includes("access") ? "primary" : "default"}
							aria-label="add"
							className={classes.fabButton}
							onClick={() =>
								history.push({ pathname, search: "?component=access" })
							}
						>
							<SvgIcon>
								<Access />
							</SvgIcon>
						</Fab>
					)}

					{Boolean(iaq) && (
						<Fab
							color={search.includes("iaq") ? "primary" : "default"}
							aria-label="add"
							className={classes.fabButton}
							onClick={() =>
								history.push({ pathname, search: "?component=iaq" })
							}
						>
							<SvgIcon>
								<Air />
							</SvgIcon>
						</Fab>
					)}

					{Boolean(thermostat) && (
						<Fab
							color={search.includes("thermostat") ? "primary" : "default"}
							aria-label="add"
							className={classes.fabButton}
							onClick={() =>
								history.push({ pathname, search: "?component=thermostat" })
							}
						>
							<SvgIcon>
								<Climate />
							</SvgIcon>
						</Fab>
					)}

					{Boolean(schedule) && (
						<Fab
							color={search.includes("schedule") ? "primary" : "default"}
							aria-label="add"
							className={classes.fabButton}
							onClick={() =>
								history.push({ pathname, search: "?component=schedule" })
							}
						>
							<SvgIcon>
								<Schedule />
							</SvgIcon>
						</Fab>
					)}

					{Boolean(lights) && (
						<Fab
							color={search.includes("lights") ? "primary" : "default"}
							aria-label="add"
							className={classes.fabButton}
							onClick={() =>
								history.push({
									pathname,
									search: "?component=lights",
								})
							}
						>
							<SvgIcon>
								<Lighting />
							</SvgIcon>
						</Fab>
					)}
				</Toolbar>
			</GlassPaper>
		</AppBar>
	);
};
