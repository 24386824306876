import React from "react";
import classNames from "classnames";

// Redux / Fbase
import { useSelector } from "react-redux";
import { useFirebase } from "react-redux-firebase";

//MUI
import {
	Button,
	Card,
	CardHeader,
	Grid,
	InputAdornment,
	Slide,
	TextField,
	Container,
	createStyles,
	Theme,
} from "@material-ui/core";

// MUI Hooks
import { makeStyles } from "@material-ui/core/styles";

//Icons
import { Visibility, VisibilityOff } from "@material-ui/icons";

import TapaLogo from "../../static/icons/tapaIcon.png";

// Interfaces
import { RootState } from "../../app/store";

// Router
import { useHistory } from "react-router";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		card: {
			justifyContent: "center",
			height: 550,
			maxWidth: 345,
			padding: "2em",
			margin: "0 auto",
			position: "relative",
			borderRadius: 0,
			boxShadow: "none",
		},
		cardHeader: {
			textAlign: "center",
			paddingBottom: "2rem",
		},
		cardHeaderRoot: {
			padding: 0,
		},
		textField: {
			fontSize: "1em",
		},

		margin: {
			margin: "1em 0",
		},
		button: {
			color: "white",
			borderRadius: 0,
			padding: "1em",
		},
	})
);

const Login = () => {
	const classes = useStyles();

	// State
	const [errors, setErrors] = React.useState("");
	const [isFlipped] = React.useState(false);
	const [showPassword, setShowPassword] = React.useState(false);

	const [state, setState] = React.useState({
		email: "",
		password: "",
	});
	const { email, password } = state;

	const auth = useSelector((state: RootState) => state.firebase.auth);
	const history = useHistory();

	React.useEffect(() => {
		if (Boolean(auth?.isLoaded && !auth?.isEmpty)) {
			history.push("/home");
		}
		// no need to add history
		// eslint-disable-next-line
	}, [auth]);

	// Firebase
	const firebase = useFirebase();

	// Handlers
	// TextField
	const handleChange = (name: string) => (e: any) => {
		setState({ ...state, [name]: e.target.value });
	};

	// Submit
	const handleSignIn = (e: any) => {
		e.preventDefault();
		firebase
			.login({
				email,
				password,
			})
			.catch((e: any) => {
				setErrors(e?.message);
			});
	};

	return (
		<Container
			maxWidth="md"
			style={{
				display: "flex",
				height: "100%",
				position: "relative",
				justifyContent: "flex-end",
				marginTop: "1rem",
			}}
		>
			<Card className={classes.card}>
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						paddingBottom: "2rem",
					}}
				>
					<img alt={"tapa logo"} src={TapaLogo} height={80} />
				</div>
				{errors ? (
					<CardHeader
						className={classNames(classes.cardHeader)}
						title={isFlipped ? "Email in Use" : "SIGN IN"}
						subheader={errors}
					/>
				) : (
					<CardHeader
						className={classNames(classes.cardHeader)}
						title={isFlipped ? "SIGN UP" : "SIGN IN"}
						classes={{
							root: classes.cardHeaderRoot,
						}}
					/>
				)}

				{/* Default */}
				<Slide direction="left" in={!isFlipped} mountOnEnter unmountOnExit>
					<Grid container style={{ display: "flex", justifyContent: "center" }}>
						<form action="/" onSubmit={handleSignIn}>
							<Grid item xs={12}>
								<TextField
									className={classNames(classes.textField, classes.margin)}
									onChange={handleChange("email")}
									required
									variant="outlined"
									fullWidth
									value={email}
									label="Email"
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									className={classNames(classes.textField, classes.margin)}
									required
									variant="outlined"
									label={"Password"}
									fullWidth
									type={showPassword ? "text" : "password"}
									value={password}
									onChange={handleChange("password")}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												{showPassword ? (
													<VisibilityOff
														onClick={() => setShowPassword(!showPassword)}
													/>
												) : (
													<Visibility
														onClick={() => setShowPassword(!showPassword)}
													/>
												)}
											</InputAdornment>
										),
									}}
								/>
							</Grid>
							<Grid item xs={12} style={{ paddingTop: "1em" }}>
								<Button
									fullWidth
									type="submit"
									variant="contained"
									color="primary"
									className={classes.button}
									onClick={handleSignIn}
									disabled={email === "" || password === ""}
								>
									sign in
								</Button>
							</Grid>
						</form>
					</Grid>
				</Slide>
			</Card>
		</Container>
	);
};

export default Login;
