import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { functions } from "../../firebase/firebase";

const fetchEndpoint = functions.httpsCallable("fetchEndpoint");

export const fetchClients: any = createAsyncThunk(
	"clients/fetchClients",
	async (api: string) => {
		const response = await fetchEndpoint({ route: "/clients", api });
		const parsedResponse = JSON.parse(response.data);

		if (Array.isArray(parsedResponse) && parsedResponse.length) {
			const callback = (acc: any, cv: any) => {
				acc[cv.client.id] = cv.client;
				return acc;
			};
			return parsedResponse.reduce(callback, {});
		} else {
			return {};
		}
	}
);

export const clientsSlice = createSlice({
	name: "clients",
	initialState: {
		data: {},
		status: "",
		error: "",
	},
	reducers: {},
	extraReducers: {
		[fetchClients.pending]: (state, action) => {
			state.status = "loading";
		},
		[fetchClients.fulfilled]: (state, action) => {
			state.status = "succeeded";
			state.data = action.payload;
		},
		[fetchClients.rejected]: (state, action) => {
			state.status = "failed";
			state.error = action.error.message;
		},
	},
});

export default clientsSlice.reducer;
