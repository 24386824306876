import * as React from "react";

// MUI
import {
	Divider,
	Grid,
	LinearProgress,
	Theme,
	createStyles,
	makeStyles,
	useMediaQuery,
	useTheme,
	Typography,
	Breadcrumbs,
	Button,
} from "@material-ui/core";

// Router
import { useHistory, useLocation, useParams } from "react-router";

// Redux
import { fetchBuilding } from "../../features/buildings/buildingsSlice";
import { fetchDevice } from "../../features/devices/devicesSlice";
import { fetchHistory } from "../../features/history/historySlice";

import { useAppSelector } from "../../app/hooks";
import { useDispatch } from "react-redux";

// Interfaces
import { IDevice } from "../../interfaces";
import { RootState } from "../../app/store";

// DB
import { useFirebaseConnect } from "react-redux-firebase";

// Util
import { find, uniq } from "lodash";

// Components
import { GlassPaper } from "../../components/GlassPaper/GlassPaper";
import { GlassDial } from "../../components/GlassDial/GlassDial";
import { Weather } from "../../components/Weather/Weather";

// Widgets
import { DeviceLoader } from "../../components/DeviceWidgets/DeviceLoader/DeviceLoader";
import { IAQCard } from "../../components/DeviceWidgets/IAQ/IAQCard";

// Charts
import { HistoryLineChart } from "../../components/Histories/HistoryLineChart";
import { IAQDeviceDial } from "../../components/DeviceWidgets/IAQ/IAQDeviceDial";

// Icons
import { ReactComponent as PM25 } from "../../static/svg/pm25.svg";
import { ReactComponent as CO2 } from "../../static/svg/c02.svg";
import { ReactComponent as Humidity } from "../../static/svg/humidity.svg";
import { ReactComponent as Tvocs } from "../../static/svg/tvocs.svg";
import { IAQComponentCard } from "../../components/DeviceWidgets/IAQ/IAQComponentCard";

interface ISpaceProps {}

// Styles
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		// App Bar
		wrapper: {
			padding: "1rem",
			marginTop: "1rem",
		},
		container: {
			padding: "1rem",
			width: "100%",
		},
		headerButtonWrapper: {
			display: "flex",
			justifyContent: "flex-end",
			padding: "1rem",
		},
		// Generic
		spacer: {
			width: "100%",
			marginTop: "1rem",
		},
		flex: {
			display: "flex",
			flexWrap: "wrap",
			alignItems: "baseline",
		},
		fullWidth: {
			width: "100%",
		},
		spaceBetween: {
			justifyContent: "space-between",
		},
		displayStringWrapper: {
			display: "flex",
			alignItems: "center",
			fontSize: "3rem",
		},
		componentTitle: {
			fontSize: "1.5rem",
		},
		textData: {
			fontSize: "2rem",
			fontWeight: 700,
		},
		componentWrapper: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		pointWrapper: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			paddingRight: "2rem",
		},
		textWrapper: {
			display: "flex",
		},
	})
);

export const DeviceScreen: React.FC<ISpaceProps> = (props) => {
	const { buildingId, floorId, spaceId, deviceId }: any = useParams();

	// HOOKS
	// Router
	const history = useHistory();
	const { pathname, search } = useLocation();

	// Styles
	const classes = useStyles();
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down("sm"));

	// Redux
	const dispatch = useDispatch();

	// STORE
	// Firebase
	const api = useAppSelector((state: RootState) => state.firebase.profile.api);
	const uid = useAppSelector((state: any) => state.firebase?.auth?.uid);
	useFirebaseConnect([`users/${uid}/control`]);

	// Redux data
	const buildingStore = useAppSelector((state: RootState) => state.buildings); //buildings stored in redux
	const deviceStore = useAppSelector((state: RootState) => state.devices); //devices stored in redux
	const historyStore = useAppSelector((state: RootState) => state.history); //history stored in redux

	const buildingData = buildingStore.data?.[buildingId];
	const deviceData = deviceStore.data?.[deviceId];

	// If no buildingData, load it into the store
	React.useEffect(() => {
		if (!Boolean(buildingData) && buildingStore.status !== "loading") {
			dispatch(fetchBuilding({ api, id: buildingId }));
		}
	}, []);

	// If no deviceData, load it into the store
	React.useEffect(() => {
		if (!Boolean(deviceData) && deviceStore.status !== "loading") {
			dispatch(
				fetchDevice({ api, buildingId, floorId, spaceId, id: deviceId })
			);
		}
	}, []);

	// If no pointHistory, load it into the store
	React.useEffect(() => {
		if (
			Boolean(deviceData) &&
			Boolean(deviceData?.points?.length) &&
			historyStore.status !== "loading"
		) {
			deviceData.points.forEach((point: any) => {
				if (point.id) {
					dispatch(fetchHistory({ api, id: point?.id, name: point.name }));
				}
			});
		}
	}, [deviceData?.points]);

	// Don't return anything if there's no device to return
	if (!deviceData) return null;

	// If building data exists, get all associated data with spaces
	const building = buildingStore.data?.[buildingId] || {
		floors: [],
	};
	const floor = find(building.floors, ["id", floorId]) || {
		spaces: [],
	};
	const space = find(floor.spaces, ["id", spaceId]) || {
		devices: [],
	};

	// Parse out devices from space
	const thermostatDevices =
		space?.devices.filter((device: IDevice) => {
			return device.virtualProfile === "virtualThermostat";
		}) || [];

	const lightingDevices =
		space?.devices.filter((device: IDevice) => {
			return device.virtualProfile === "virtualLightSwitch";
		}) || [];

	const airQualityDevices =
		space?.devices.filter((device: IDevice) => {
			return device.virtualProfile === "virtualAirQuality";
		}) || [];

	// Convenience ob to check for existence of device
	const isDeviceType = {
		access: Boolean(deviceData.virtualProfile === "virtualAccess"),
		blinds: Boolean(deviceData.virtualProfile === "virtualBlinds"),
		iaq: Boolean(deviceData.virtualProfile === "virtualAirQuality"),
		lights: Boolean(deviceData.virtualProfile === "virtualLightSwitch"),
		schedule: Boolean(deviceData.virtualProfile === "virtualSchedule"),
		thermostat: Boolean(deviceData.virtualProfile === "virtualThermostat"),
	};

	const isLoading = Boolean(
		buildingStore.status === "loading" || deviceStore.status === "loading"
	);

	const co2 = find(deviceData.points, ["virtualDeviceSlot", "co2"]);
	const humidity = find(deviceData.points, ["virtualDeviceSlot", "humidity"]);
	const pm25 = find(deviceData.points, ["virtualDeviceSlot", "pm25"]);
	const voc = find(deviceData.points, ["virtualDeviceSlot", "voc"]);

	console.log(isDeviceType);

	return (
		<>
			{isLoading ? (
				<LinearProgress style={{ width: "100%" }} />
			) : (
				<div style={{ display: "flex", flexWrap: "wrap", padding: 0 }}>
					{/* Metadata Components */}
					<div
						style={{
							display: "flex",
							width: "100%",
							justifyContent: "space-evenly",
						}}
					>
						<GlassPaper square style={{ width: "100%" }}>
							<Grid container>
								{/* Header */}
								<Grid
									item
									xs={12}
									style={{
										display: "flex",
										justifyContent: "space-between",
										flexWrap: "wrap",
										alignItems: "center",
										padding: "0 1rem",
									}}
								>
									<Typography
										variant="h5"
										style={{ padding: "1rem", fontWeight: 700, flexGrow: 1 }}
									>
										{deviceData.name}
									</Typography>

									<div style={{ display: "flex" }}>
										<Breadcrumbs>
											<Button
												onClick={() =>
													history.push(
														`/space/${building.id}/${floor.id}/${space.id}`
													)
												}
											>
												{space.name}
											</Button>
											<Typography variant="body1" style={{ fontWeight: 700 }}>
												{deviceData.name}
											</Typography>
										</Breadcrumbs>
									</div>
								</Grid>

								<Divider style={{ width: "100%" }} />
							</Grid>
						</GlassPaper>
					</div>

					<Divider style={{ width: "100%" }} />

					{/* Components */}
					{Boolean(isDeviceType["iaq"]) && (
						<Grid container style={{ margin: "2rem 0" }}>
							<Grid
								item
								xs={12}
								sm={6}
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									padding: "1rem",
								}}
							>
								<IAQDeviceDial iaqDevice={deviceData} />
							</Grid>

							{/* Components */}

							<Grid
								item
								xs={12}
								sm={6}
								style={{
									display: "flex",
									flexWrap: "wrap",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<GlassPaper
									square
									style={{
										display: "flex",
										padding: "2rem",
										flexDirection: "column",
									}}
								>
									{/* CO2 */}
									{Boolean(co2) && (
										<Grid item xs={12} className={classes.componentWrapper}>
											{
												<IAQComponentCard
													name={"CO2"}
													iaqComponent={co2}
													icon={
														<CO2 height="2rem" style={{ margin: "0 .5rem" }} />
													}
												/>
											}
										</Grid>
									)}

									{/* Humidity */}
									{Boolean(humidity) && (
										<Grid item xs={12} className={classes.componentWrapper}>
											{
												<IAQComponentCard
													name={"Humidity"}
													iaqComponent={humidity}
													icon={
														<Humidity
															height="2rem"
															style={{ margin: "0 .5rem" }}
														/>
													}
												/>
											}
										</Grid>
									)}

									{/* PM25 */}
									{Boolean(pm25) && (
										<Grid item xs={12} className={classes.componentWrapper}>
											{
												<IAQComponentCard
													name="PM25"
													iaqComponent={pm25}
													icon={
														<PM25 height="2rem" style={{ margin: "0 .5rem" }} />
													}
												/>
											}
										</Grid>
									)}

									{/* Tvocs */}
									{Boolean(voc) && (
										<Grid item xs={12} className={classes.componentWrapper}>
											{
												<IAQComponentCard
													name="VOCs"
													iaqComponent={voc}
													icon={
														<Tvocs
															height="2rem"
															style={{ margin: "0 .5rem" }}
														/>
													}
												/>
											}
										</Grid>
									)}
								</GlassPaper>
							</Grid>
						</Grid>
					)}

					{/* Histories */}
					<Grid container style={{ margin: "1rem" }}>
						{/* Iterate through points */}
						{deviceData?.points?.map((point: any) => {
							// Look for historyData in the store (loads in useEffect above)
							const historyData = historyStore.data[point.id];

							// If it exists, return a GlassPaper HistoryLineChart
							return (
								Boolean(historyData && historyData?.data?.length) && (
									<Grid key={point.id} item xs={12} sm={4}>
										<GlassPaper
											// disableBorders
											disableEffects
											square
											style={{ padding: "1rem 0" }}
										>
											<HistoryLineChart
												historyData={historyData}
												height={120}
												sampleNth={20}
												showTooltip={false}
											/>
										</GlassPaper>
									</Grid>
								)
							);
						})}
					</Grid>
				</div>
			)}
		</>
	);
};
