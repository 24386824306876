import * as React from "react";

// MUI
import {
	Button,
	Container,
	createStyles,
	Divider,
	Grid,
	makeStyles,
	Theme,
	Typography,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";

// MUI Lab
import { Skeleton } from "@material-ui/lab";

// Util
import { find, orderBy, uniq } from "lodash";
import clsx from "clsx";

// Hooks
// Firebase
import { useFirebaseConnect } from "react-redux-firebase";

// Redux
import { useAppSelector } from "../../app/hooks";
import { useDispatch } from "react-redux";
import { fetchBuilding } from "../../features/buildings/buildingsSlice";

// Router
import { useHistory } from "react-router";

// Components
import { GlassPaper } from "../../components/GlassPaper/GlassPaper";
import {
	IFavoriteDataPayload,
	FavoriteListItem,
} from "../../components/FavoriteListItem/FavoriteListItem";

// Interfaces
import { IFavoriteSpace } from "../../interfaces";
import { RootState } from "../../app/store";

// Styles
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		// App Bar
		wrapper: {
			padding: "1rem",
			marginTop: "1rem",
		},
		container: {
			padding: "1rem",
			width: "100%",
		},
		headerButtonWrapper: {
			display: "flex",
			justifyContent: "flex-end",
			padding: "1rem",
		},
		// Generic
		spacer: {
			width: "100%",
			marginTop: "1rem",
		},
		flex: {
			display: "flex",
			flexWrap: "wrap",
			alignItems: "baseline",
		},
		fullWidth: {
			width: "100%",
		},
		spaceBetween: {
			justifyContent: "space-between",
		},
	})
);

interface IHomeProps {}

export const Home: React.FC<IHomeProps> = (props) => {
	// HOOKS
	// Router
	const history = useHistory();
	// Styles
	const classes = useStyles();
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down("sm"));
	// Redux
	const dispatch = useDispatch();

	// STORE
	// Firebase
	const api = useAppSelector((state: RootState) => state.firebase.profile.api);
	const uid = useAppSelector((state: any) => state.firebase?.auth?.uid);
	useFirebaseConnect([`users/${uid}/control`]);

	// Firebase Data
	const profile = useAppSelector((state: any) => state.firebase?.profile);
	const control = useAppSelector(
		(state: any) => state.firebase.ordered?.users?.[uid]?.control
	);

	// Building data
	const buildingStore = useAppSelector((state: RootState) => state.buildings); //buildings fetched and stored on selection

	// Favorites from DB
	const favoriteSpacesFetch = Array.isArray(control)
		? find(control, ["key", "favoriteSpaces"]) || { key: "", value: {} }
		: { key: "", value: {} };
	const favoriteSpaces = Object.keys(favoriteSpacesFetch.value).map(
		(key: string) => ({ key, ...favoriteSpacesFetch.value[key] })
	);

	const favoriteDevicesFetch = Array.isArray(control)
		? find(control, ["key", "favoriteDevices"]) || { key: "", value: {} }
		: { key: "", value: {} };
	const favoriteDevices = Object.keys(favoriteDevicesFetch.value).map(
		(key: string) => ({ key, ...favoriteDevicesFetch.value[key] })
	);

	const noFavorites =
		!Boolean(favoriteSpaces?.length) && !Boolean(favoriteDevices?.length);

	React.useEffect(() => {
		const buildingIds = favoriteSpaces.map((space: any) => space.buildingId);
		const uniqueBuildingIds = uniq(buildingIds);

		uniqueBuildingIds.forEach((id: string) => {
			if (
				!Boolean(buildingStore.data[id]) &&
				buildingStore.status !== "loading"
			) {
				dispatch(fetchBuilding({ api, id }));
			}
		});
	}, [favoriteSpaces.length]);

	return (
		<Container className={classes.wrapper}>
			{/* Header */}
			<GlassPaper style={{ width: "100%" }}>
				<Grid container className={classes.container}>
					<Grid item xs={12}>
						<Typography variant="h5" className={classes.fullWidth} paragraph>
							{profile.firstName
								? `Welcome, ${profile.firstName}!`
								: "Welcome!"}
						</Typography>
						<Typography variant="body1" className={classes.fullWidth}>
							This is your homepage. Spaces and devices that you favorite will
							appear here. You can search for devices to add to your homepage by
							either clicking on the spaces icon below
						</Typography>
					</Grid>

					{/* If no favorites, add button to spaces */}
					{Boolean(noFavorites) && (
						<Grid className={classes.headerButtonWrapper} item xs={12}>
							<Button
								variant="contained"
								color="secondary"
								onClick={() => history.push("/spaces")}
							>
								Add Favorites
							</Button>
						</Grid>
					)}
				</Grid>
			</GlassPaper>

			<div className={classes.spacer} />

			{/* Favorites */}
			<Grid container>
				{/* Favorite Spaces */}
				<Grid item xs={12} sm={6} className={classes.flex}>
					<GlassPaper
						fullWidth
						style={{ marginRight: mobile ? 0 : "1rem", padding: "1rem" }}
					>
						{/* Header */}
						<div
							className={clsx(
								classes.flex,
								classes.spaceBetween,
								classes.fullWidth
							)}
						>
							<Typography variant="h5">Spaces</Typography>
							<Button onClick={() => history.push("/spaces")}>
								View Spaces
							</Button>
						</div>

						<Divider style={{ margin: "1rem 0" }} />

						{/* Space Cards */}
						{orderBy(favoriteSpaces, "name").map(
							(favoriteSpace: IFavoriteSpace) => {
								// if the buildings are still loading, show skeleton
								if (
									buildingStore.status === "loading" ||
									Boolean(!buildingStore.data?.[favoriteSpace.buildingId])
								) {
									return <Skeleton key={favoriteSpace.spaceId} height={50} />;
								}

								// If building data exists, get all associated data with spaces
								const building = buildingStore.data?.[
									favoriteSpace.buildingId
								] || { floors: [] };
								const floor = find(building.floors, [
									"id",
									favoriteSpace.floorId,
								]) || { spaces: [] };
								const space = find(floor.spaces, ["id", favoriteSpace.spaceId]);

								// Convenience Payload
								const favoriteDataPayload: IFavoriteDataPayload = {
									name: favoriteSpace.name,
									buildingId: building.id,
									floorId: floor.id,
									spaceId: space.id,
									deviceId: "",
									type: "space",
								};

								return (
									<GlassPaper key={favoriteSpace.spaceId} square>
										<FavoriteListItem
											favoriteData={favoriteSpace}
											favoriteDataPayload={favoriteDataPayload}
											data={space}
											buildingData={building}
											floorData={floor}
											spaceData={space}
										/>
									</GlassPaper>
								);
							}
						)}
					</GlassPaper>
				</Grid>

				{Boolean(mobile) && <div className={classes.spacer} />}

				{/* Favorite Devices */}
				<Grid item xs={12} sm={6} className={classes.flex}>
					<GlassPaper
						fullWidth
						style={{ marginLeft: mobile ? 0 : "1rem", padding: "1rem" }}
					>
						{/* Header */}
						<div
							className={clsx(
								classes.flex,
								classes.spaceBetween,
								classes.fullWidth
							)}
						>
							<Typography variant="h5">Devices</Typography>
						</div>

						<Divider style={{ margin: "1rem 0" }} />

						{/* Device Cards */}
						{orderBy(favoriteDevices, "name").map(
							(favoriteDevice: IFavoriteSpace) => {
								// if the buildings are still loading, show skeleton
								if (
									buildingStore.status === "loading" ||
									Boolean(!buildingStore.data?.[favoriteDevice.buildingId])
								) {
									return <Skeleton key={favoriteDevice.deviceId} height={50} />;
								}

								// If building data exists, get all associated data with spaces
								const building = buildingStore.data?.[
									favoriteDevice.buildingId
								] || { floors: [] };
								const floor = find(building.floors, [
									"id",
									favoriteDevice.floorId,
								]) || { spaces: [] };
								const space = find(floor.spaces, [
									"id",
									favoriteDevice.spaceId,
								]) || { devices: [] };
								const device = find(space.devices, [
									"id",
									favoriteDevice.deviceId,
								]);

								// Convenience Payload
								const favoriteDataPayload: IFavoriteDataPayload = {
									name: favoriteDevice.name,
									buildingId: building.id,
									floorId: floor.id,
									spaceId: space.id,
									deviceId: favoriteDevice.deviceId,
									type: "device",
								};

								return (
									<GlassPaper key={favoriteDevice.deviceId} square>
										<FavoriteListItem
											favoriteData={favoriteDevice}
											favoriteDataPayload={favoriteDataPayload}
											data={device}
											buildingData={building}
											floorData={floor}
											spaceData={space}
										/>
									</GlassPaper>
								);
							}
						)}
					</GlassPaper>
				</Grid>
			</Grid>
		</Container>
	);
};
