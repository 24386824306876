import React from "react";

// Styles
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

// MUI
import { AppBar, Toolbar, Fab, SvgIcon } from "@material-ui/core";

// Icons
import { ReactComponent as Home } from "../../../static/svg/home.svg";
import { ReactComponent as Buildings } from "../../../static/svg/buildings.svg";
import { ReactComponent as Favorites } from "../../../static/svg/favorites.svg";
import { ReactComponent as Messages } from "../../../static/svg/messages.svg";
import { ReactComponent as Spaces } from "../../../static/svg/spaces.svg";

import { GlassPaper } from "../../GlassPaper/GlassPaper";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		appBar: {
			top: "auto",
			bottom: 0,
		},
		toolbar: {
			display: "flex",
			justifyContent: "space-evenly",
			padding: "1rem",
		},
		fabButton: {
			margin: "0 auto",
		},
	})
);

export const DashboardAppBarBottom: any = (props: any) => {
	const classes = useStyles();
	const history = useHistory();

	const { pathname } = history?.location;

	return (
		<AppBar position="fixed" color="transparent" className={classes.appBar}>
			<GlassPaper
				square
				style={{ position: "fixed", width: "100%", bottom: 0 }}
			>
				<Toolbar className={classes.toolbar}>
					<Fab
						color={pathname === "/home" ? "primary" : "default"}
						aria-label="add"
						className={classes.fabButton}
						onClick={() => history.push("/home")}
					>
						<SvgIcon>
							<Home />
						</SvgIcon>
					</Fab>

					<Fab
						color={pathname === "/spaces" ? "primary" : "default"}
						aria-label="add"
						className={classes.fabButton}
						onClick={() => history.push("/spaces")}
					>
						<SvgIcon>
							<Spaces />
						</SvgIcon>
					</Fab>

					<Fab
						color={pathname === "/favorites" ? "primary" : "default"}
						aria-label="add"
						className={classes.fabButton}
						onClick={() => history.push("/favorites")}
					>
						<SvgIcon>
							<Favorites />
						</SvgIcon>
					</Fab>

					<Fab
						color={pathname === "/buildings" ? "primary" : "default"}
						aria-label="add"
						className={classes.fabButton}
						onClick={() => history.push("/buildings")}
					>
						<SvgIcon>
							<Buildings />
						</SvgIcon>
					</Fab>
					<Fab
						color={pathname === "/messages" ? "primary" : "default"}
						aria-label="add"
						className={classes.fabButton}
						onClick={() => history.push("/messages")}
					>
						<SvgIcon>
							<Messages />
						</SvgIcon>
					</Fab>
				</Toolbar>
			</GlassPaper>
		</AppBar>
	);
};
