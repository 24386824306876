import * as React from "react";

// MUI
import {
	Button,
	Dialog,
	DialogTitle,
	Divider,
	Grid,
	IconButton,
	ListItem,
	ListItemIcon,
	ListItemText,
	ListItemSecondaryAction,

	// Util
	createStyles,
	makeStyles,
	useTheme,
	useMediaQuery,
	Theme,
} from "@material-ui/core";

// Lab
import { Skeleton } from "@material-ui/lab";

// Icons
import { Clear, Info, Star } from "@material-ui/icons";

// Colors
import { yellow } from "@material-ui/core/colors";

// Components
import { GlassPaper } from "../GlassPaper/GlassPaper";
import { MetadataDial } from "../MetadataDial/MetadataDial";

// Util
import clsx from "clsx";
import { isEmpty } from "lodash";

// Interfaces
import { IFavoriteData } from "../../pages/Navigation/Spaces";
import { IMetadataPayload } from "../../interfaces";
import { RootState } from "../../app/store";

// Hooks
import { useAppSelector } from "../../app/hooks";
import { useFirebase } from "react-redux-firebase";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import { Weather } from "../Weather/Weather";

export interface IFavoriteDataPayload {
	name: string;
	buildingId: string;
	floorId: string;
	spaceId: string;
	deviceId: string;
	type: string;
}

interface IData {
	devices: any[];
	id: string;
	metadata: {
		airQuality: IMetadataPayload | {} | any;
		temperature: IMetadataPayload | {} | any;
	};
	name: string;
}

interface IFavoriteListItem {
	favoriteData: IFavoriteData | undefined;
	favoriteDataPayload: IFavoriteDataPayload;
	data: IData;
	buildingData?: any;
	floorData?: any;
	spaceData?: any;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			width: "100%",
			padding: "1rem",
		},
		favorite: {
			color: yellow[500],
			stroke: "rgba(0,0,0,.25)",
		},
		primaryTextTitle: {
			fontSize: "1.5rem",
			fontWeight: 700,
		},

		// State
		transparent: {
			background: "transparent",
			boxShadow: "none",
		},
		blur: {
			backdropFilter: "blur(7px)",
		},
	})
);

// ** AS FAVORITELISTITEM HANDLES BOTH space AND device || MAKE SURE ANY CHANGES TAKE BOTH INTO ACCOUNT
// ** EASIEST WAY TO FIND OUT IS: favoriteDataPayload.type === "device" || "space"
export const FavoriteListItem: React.FC<IFavoriteListItem> = ({
	data,
	favoriteData,
	favoriteDataPayload,
	buildingData,
	floorData,
	spaceData,
}) => {
	// Hooks
	// Styles
	const classes = useStyles();
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down("sm"));

	// Router
	const history = useHistory();

	// Store
	const buildingStore = useAppSelector((state: RootState) => state.buildings); //buildings fetched and stored on selection

	// DB
	const rrf = useFirebase();
	const uid = useAppSelector<string>((state: any) => state.firebase?.auth?.uid);

	// UI
	const snackbar = useSnackbar();

	// State
	const [open, setOpen] = React.useState(false);

	// Handle info
	const handleInfoOpen = () => {
		setOpen(true);
	};
	const handleInfoClose = () => {
		setOpen(false);
	};

	// Convenience
	const isDevice = Boolean(favoriteDataPayload.type === "device");
	const favoriteDBRoute = isDevice
		? `users/${uid}/control/favoriteDevices`
		: `users/${uid}/control/favoriteSpaces`;
	const clickRoute = isDevice
		? `/device/${buildingData.id}/${floorData.id}/${spaceData.id}/${data.id}`
		: `/space/${buildingData.id}/${floorData.id}/${data.id}`;

	const secondaryTextString = Boolean(buildingData?.name && floorData?.name)
		? `${buildingData.name} / ${floorData.name} ${
				isDevice ? `/ ${spaceData.name}` : ""
		  }`
		: "";

	// Handle favorite
	const handleFavorite = () => {
		// Remove
		if (Boolean(favoriteData) && favoriteData !== void 0) {
			const removeRole = rrf.set(
				`${favoriteDBRoute}/${favoriteData.key}`,
				null
			);
			removeRole.then((res: any) => {
				snackbar.enqueueSnackbar(`${data.name} removed from your favorites`, {
					variant: "warning",
					autoHideDuration: 2000,
				});
			});
		} else {
			// Add
			const roleAdded = rrf.push(favoriteDBRoute, favoriteDataPayload);
			roleAdded.then((res: any) => {
				snackbar.enqueueSnackbar(`${data.name} added to your favorites`, {
					variant: "success",
					autoHideDuration: 2000,
				});
			});
		}
	};

	if (!buildingData || buildingStore.status === "loading") {
		return <Skeleton height={50} />;
	}

	return (
		<>
			<ListItem className={classes.container}>
				<GlassPaper
					style={{
						display: "flex",
						flexWrap: "wrap",
						alignItems: "center",
						width: "100%",
					}}
				>
					<ListItemIcon>
						<IconButton
							onClick={() => handleInfoOpen()}
							disabled={!data.devices || !data.devices?.length}
						>
							<Info />
						</IconButton>
					</ListItemIcon>

					<Button
						style={{ textAlign: "left", textTransform: "none", flexGrow: 1 }}
						onClick={() => history.push(clickRoute)}
					>
						<ListItemText primary={data.name} secondary={secondaryTextString} />
					</Button>

					<div style={{ display: "flex", width: 50 }} />
					<ListItemSecondaryAction style={{ right: 0 }}>
						<IconButton onClick={() => handleFavorite()}>
							<Star
								className={clsx({
									[classes.favorite]: Boolean(favoriteData),
								})}
							/>
						</IconButton>
					</ListItemSecondaryAction>
				</GlassPaper>
			</ListItem>

			{/* Info Dialog */}
			<Dialog
				fullScreen={mobile}
				onClose={handleInfoClose}
				aria-labelledby="simple-dialog-title"
				open={open}
				classes={{ paper: classes.transparent, scrollPaper: classes.blur }}
			>
				{Boolean(mobile) && (
					<Button onClick={handleInfoClose} style={{ padding: "1rem" }}>
						<Clear />
					</Button>
				)}

				<GlassPaper square={Boolean(mobile)}>
					{Boolean(buildingData) && (
						<DialogTitle id="simple-dialog-title">
							<ListItemText
								classes={{ primary: classes.primaryTextTitle }}
								primary={`${data.name} (${buildingData.name})`}
								secondary={`${buildingData.address}, ${buildingData.city}, ${buildingData.state}, ${buildingData.zip}`}
							/>
						</DialogTitle>
					)}

					<Divider />

					{/* Body */}
					<Grid container style={{ display: "flex", justifyContent: "center" }}>
						{/* Weather */}
						<Grid item xs={12} style={{ padding: "1rem 2rem" }}>
							{Boolean(buildingData) && (
								<GlassPaper style={{ padding: "1rem" }}>
									<Weather
										backgroundImage={false}
										address={`${buildingData.address}, ${buildingData.city}, ${buildingData.state}, ${buildingData.zip}`}
										city={buildingData.city}
										id={buildingData.id}
									/>
								</GlassPaper>
							)}
						</Grid>

						{/* Air Quality Metadata */}
						{Boolean(
							data.metadata?.airQuality || data.metadata?.temperature
						) && (
							<GlassPaper
								style={{
									display: "flex",
									justifyContent: "space-evenly",
									margin: "2rem",
									width: "100%",
								}}
							>
								<Grid container>
									<Grid item xs={12} sm={6}>
										{Boolean(
											data.metadata && !isEmpty(data.metadata?.airQuality)
										) && (
											<MetadataDial
												colorByValue
												title={`${data.name} Air Quality`}
												metadata={data.metadata?.airQuality}
											/>
										)}
									</Grid>

									{/* Temperature Metadata */}
									<Grid item xs={12} sm={6}>
										{Boolean(
											data.metadata && !isEmpty(data.metadata?.temperature)
										) && (
											<MetadataDial
												title={`${data.name} Temperature`}
												metadata={data.metadata?.temperature}
												appendToValue={"°"}
												min={0}
												max={120}
											/>
										)}
									</Grid>
								</Grid>
							</GlassPaper>
						)}
					</Grid>
				</GlassPaper>
			</Dialog>
		</>
	);
};
