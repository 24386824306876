import * as React from "react";

// Lab
import { Skeleton } from "@material-ui/lab";

// Redux
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../app/hooks";
import { fetchDevice } from "../../../features/devices/devicesSlice";

// Interfaces
import { RootState } from "../../../app/store";
import { IPoint } from "../../../interfaces";
import { GlassPaper } from "../../GlassPaper/GlassPaper";

interface IDeviceData {
	id: string;
	name: string;
	points: IPoint | any[];
	virtualProfile: string;
}

interface IDeviceLoader {
	device: IDeviceData;
	buildingId: string;
	floorId: string;
	spaceId: string;
	children: any;
}

export const DeviceLoader: any = ({
	device,
	buildingId,
	floorId,
	spaceId,
	children,
}: IDeviceLoader) => {
	const dispatch = useDispatch();

	const api = useAppSelector((state: RootState) => state.firebase.profile.api);

	// Device data
	const deviceStore = useAppSelector((state: RootState) => state.devices); //devices fetched and stored on selection
	const deviceData = deviceStore.data?.[device.id];

	// If no device data, load it into the store
	React.useEffect(() => {
		if (!Boolean(deviceData) && deviceStore.status !== "loading") {
			dispatch(
				fetchDevice({ api, id: device.id, buildingId, floorId, spaceId })
			);
		}
	}, []);

	if (deviceStore.status === "loading") {
		return (
			<Skeleton
				style={{ transform: "none", minHeight: 140 }}
				height="100%"
				width="100%"
			/>
		);
	}

	return <>{children}</>;
};
