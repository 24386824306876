import * as React from "react";

import { makeStyles, Theme, Typography, Zoom } from "@material-ui/core";

import clsx from "clsx";
import { ArrowDropDownRounded, Lock } from "@material-ui/icons";

// Styles
const useStyles = makeStyles(
	(theme: Theme) => ({
		wrapper: {
			position: "relative",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		},

		// Knob
		"@keyframes pulse": {
			"0%": {
				boxShadow: (props: any) => `0 0 10px 0px ${props.color}33`,
				border: (props: any) => `1px solid ${props.color}50`,
			},
			"75%": {
				boxShadow: (props: any) => `0 0 30px 5px ${props.color}50`,
				border: (props: any) => `1px solid ${props.color}90`,
			},
			"100": {
				boxShadow: (props: any) => `0 0 10px 0px ${props.color}33`,
				border: (props: any) => `1px solid ${props.color}50`,
			},
		},
		knob: {
			display: "flex",
			position: "relative",
			justifyContent: "center",
		},
		knobOuter: {
			borderRadius: "50%",
			background: theme.palette.background.paper,
			border: (props: any) =>
				props.color
					? `1px solid ${props.color}50`
					: "1px solid rgb(206 206 206 / 50%)",
			boxShadow: (props: any) =>
				props.color
					? `0 0 10px 0px ${props.color}33`
					: `0 0 50px 0px rgb(206 206 206 / 74%)`,
			transform: "scale(.85)",
			transition: "box-shadow .5s ease-in-out, border .5s ease-in-out",
			animation: (props: any) =>
				props.color ? `$pulse 2500ms ease-in-out infinite` : "",
		},
		knobOuterBorder: {
			position: "absolute",
			background: (props: any) =>
				props.color ? `${props.color}10` : theme.palette.background.default,
			borderRadius: "50%",
			boxShadow: (props: any) =>
				props.color
					? `0 0 0px 3px ${props.color}40`
					: `0 0 20px 0px rgb(206 206 206 / 74%)`,
			transition: "box-shadow .5s ease-in-out, background .5s ease-in-out",
		},
		knobInner: {
			borderRadius: "50%",
		},
		knobInnerGrip: {
			position: "absolute",
			left: "45%",
			top: "90%",
			transform: "translateX(-30%)",
			color: (props: any) =>
				props.color ? props.color : theme.palette.action.disabled,
			fontSize: "3rem",
			transition: "color .2s ease-in-out",
		},

		// Ticks
		ticks: {
			position: "absolute",
			left: -10,
		},
		tick: {
			position: "absolute",
			background: "transparent",
			borderBottom: `4px solid ${theme.palette.text.disabled}`,
			width: 1,
		},
		active: {
			background: theme.palette.background.default,
			borderBottom: `4px solid ${theme.palette.common.black}`,
			transition: "border-bottom 0.25s",
			width: 1,
		},
		currentValue: {
			background: theme.palette.background.default,
			borderBottom: (props: any) =>
				props.color
					? `25px solid ${props.color}`
					: `30px solid ${theme.palette.common.black}`,
			transition: "border-bottom 0.25s",
			width: 2,
		},
		currentSetpoint: {
			background: theme.palette.background.default,
			borderBottom: (props: any) =>
				props.color
					? `15px solid ${props.color}`
					: `15px solid ${theme.palette.common.black}`,
			width: 2,
		},
		intermediateTicks: {
			background: theme.palette.background.default,
			borderBottom: (props: any) =>
				props.color
					? `4px solid ${props.color}`
					: `4px solid ${theme.palette.common.black}`,
			width: 3,
		},

		// Labels
		labelContainer: {
			position: "absolute",
			zIndex: 4,
		},
		setpointLabel: {
			color: (props: any) => (props.color ? props.color : "inherit"),
		},
		labelWrapper: {
			display: "flex",
			justifyContent: "space-between",
		},
		valueLabel: {
			position: "absolute",
		},
		statusLabel: {
			userSelect: "none",
			width: "100%",
			position: "absolute",
			justifyContent: "center",
			textAlign: "center",
			color: (props: any) => (props.color ? props.color : "inherit"),
		},
	}),
	{ link: true }
);

interface IDialProps {
	size: number;
	min: number;
	max: number;
	numTicks: number;
	degrees: number;
	displayValue: number;
	setpointValue?: number;
	color?: string;
	showLabels?: boolean;
	statusLabel?: string;
	modeLabel?: string | any;
	disabled?: boolean;
	handleChange?: any;
}

export const Dial: React.FC<IDialProps> = ({
	size = 0,
	min = 0,
	max = 0,
	numTicks = 0,
	degrees = 0,
	displayValue = 0,
	setpointValue = 0,
	color = "",
	showLabels = false,
	statusLabel = "",
	modeLabel = "",
	disabled = false,
	handleChange = () => {},
}: IDialProps) => {
	const classes = useStyles({ color });

	// Vars
	const fullAngle = degrees;
	const startAngle = (360 - degrees) / 2;
	const endAngle = startAngle + degrees;
	const margin = size * 0.05;

	// Current Deg
	const convertRange = (
		oldMin: number,
		oldMax: number,
		newMin: number,
		newMax: number,
		oldValue: number
	) => {
		return (
			((oldValue - oldMin) * (newMax - newMin)) / (oldMax - oldMin) + newMin
		);
	};

	const [currentDeg, setCurrentDeg] = React.useState(
		Math.floor(
			convertRange(
				min,
				max,
				startAngle,
				endAngle,
				setpointValue || displayValue
			)
		)
	);

	const [dragging, setDragging] = React.useState(false);

	// Get degrees
	const getDeg = (cX: number, cY: number, pts: { x: number; y: number }) => {
		const x = cX - pts.x;
		const y = cY - pts.y;

		let deg = (Math.atan(y / x) * 180) / Math.PI;
		if ((x < 0 && y >= 0) || (x < 0 && y < 0)) {
			deg += 90;
		} else {
			deg += 270;
		}

		let finalDeg = Math.min(Math.max(startAngle, deg), endAngle);
		return finalDeg;
	};

	const renderTicks = () => {
		let ticks = [];
		const incr = fullAngle / numTicks;
		const tickSize = margin + size / 2;
		for (let deg = startAngle, i = min; deg <= endAngle; deg += incr, i++) {
			const tick = {
				deg: deg,
				value: i,
				tickStyle: {
					background: "transparent",
					height: tickSize + size / 5 + 4,
					left: tickSize - 1,
					top: tickSize + 2,
					transform: "rotate(" + deg + "deg)",
					transformOrigin: "top",
				},
				labelStyle: {
					position: "absolute",
					left: -margin,
					top: size - margin * 2,
					transform: `rotate(-${deg}deg)`,
					transformOrigin: "top",
				} as React.CSSProperties,
			};
			ticks.push(tick);
		}

		return ticks;
	};

	const dcpy = (o: any) => {
		return JSON.parse(JSON.stringify(o));
	};

	const startDrag = (e: any) => {
		if (Boolean(disabled)) {
			return;
		}

		setDragging(true);

		const knob = e.target.getBoundingClientRect();
		const pts = {
			x: knob.left + knob.width / 2,
			y: knob.top + knob.height / 2,
		};

		const moveHandler = (e: any) => {
			let currentDeg = 0;

			if (e.clientX && e.clientY) {
				currentDeg = getDeg(e.clientX, e.clientY, pts);
			}

			if (e.touches) {
				currentDeg = getDeg(e.touches[0].clientX, e.touches[0].clientY, pts);
			}

			if (currentDeg === startAngle) return;

			let newValue = Math.floor(
				convertRange(startAngle, endAngle, min, max, currentDeg)
			);

			setCurrentDeg(currentDeg);

			if (handleChange) {
				handleChange(newValue);
			}
		};

		// Mousedown
		if (e.type === "mousedown") {
			moveHandler(e);
			document.addEventListener("mousemove", moveHandler);
			document.addEventListener("mouseup", (e) => {
				setDragging(false);
				document.removeEventListener("mousemove", moveHandler);
			});
		}

		// Touchstart
		if (e.type === "touchstart") {
			moveHandler(e);
			document.addEventListener("touchmove", moveHandler);
			document.addEventListener("touchend", (e) => {
				setDragging(false);
				document.removeEventListener("touchmove", moveHandler);
			});
		}
	};

	const endDrag = (e: any) => {
		setDragging(false);
	};

	// wrapper
	let wrapStyle = {
		width: size * 1.5,
		height: size * 1.5,
	};

	// knob
	let kStyle = {
		width: size,
		height: size,
	};

	// outer border
	let obStyle = {
		width: margin + size + size / 5,
		height: margin + size + size / 5,
		top: -size / 14,
	};

	// ticks
	let tStyle = {
		left: -margin,
	};

	// individual tick
	let iStyle = dcpy(kStyle);
	iStyle.transform = "rotate(" + currentDeg + "deg)";

	//  outer knob
	let oStyle = dcpy(kStyle);
	oStyle.margin = margin;

	// min max labels
	let mmStyle = {
		width: size + size / 8 + size / 2,
		top: size / 2 + 3 * margin,
	};

	// value container style
	let vcStyle = {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: size + size / 8 + size / 2,
		top: size / 2,
	};

	// value label size
	let vlStyle = {
		fontSize: `${size / 75}rem`,
		paddingLeft: ".5ch",
	};

	return (
		<div className={classes.wrapper} style={wrapStyle}>
			{/* Occupied / Unoccupied */}
			{Boolean(disabled) && (
				<div style={{ position: "absolute", top: 0, right: 0 }}>
					<Lock />
				</div>
			)}

			{/* Main */}
			<div className={classes.knob} style={kStyle}>
				{/* TICKS */}
				<div className={classes.ticks} style={tStyle}>
					{numTicks
						? renderTicks().map((tick, i) => {
								return (
									<span style={{ position: "relative" }} key={i}>
										<div
											className={clsx(classes.tick, {
												// [classes.active]: Boolean(tick.deg <= currentDeg),
												[classes.currentSetpoint]: Boolean(
													tick.value === setpointValue
												),
												[classes.currentValue]: Boolean(
													tick.value === displayValue
												),
												[classes.intermediateTicks]:
													Boolean(
														tick.value > setpointValue &&
															tick.value < displayValue
													) ||
													Boolean(
														tick.value < setpointValue &&
															tick.value > displayValue
													),
											})}
											style={tick.tickStyle}
										>
											{/* TODO: ADD SET NUMBER ABOVE TICK */}
											{Boolean(tick.value === setpointValue) && (
												<span
													className={classes.setpointLabel}
													style={tick.labelStyle}
												>
													{" "}
													{String(setpointValue) + "°"}
												</span>
											)}
										</div>
									</span>
								);
						  })
						: null}
				</div>

				{/* BORDER */}
				{/* Drag handler */}
				<div
					className={classes.knobOuterBorder}
					style={obStyle}
					onMouseDown={startDrag}
					onMouseUp={endDrag}
					onTouchStart={startDrag}
					// onMouseDown={startDrag}
					// onMouseUp={endDrag}
				/>

				{/* HANDLE */}
				{Boolean(!disabled) && (
					<div
						className={classes.knobOuter}
						style={oStyle}
						onMouseDown={startDrag}
						onMouseUp={endDrag}
						onTouchStart={startDrag}
					>
						<div className={classes.knobInner} style={iStyle}>
							{/* <div className={classes.knobInnerGrip} /> */}
							<ArrowDropDownRounded className={classes.knobInnerGrip} />
						</div>
					</div>
				)}

				{/* LABELS */}
				{/* min / max */}
				<div className={classes.labelContainer} style={mmStyle}>
					<div className={classes.labelWrapper}>
						<Typography style={{ userSelect: "none" }}>{min}°</Typography>
						<Typography style={{ userSelect: "none" }}>{max}°</Typography>
					</div>
				</div>

				{/* status */}
				{Boolean(modeLabel) && (
					<div className={classes.labelContainer} style={mmStyle}>
						<div className={classes.statusLabel} style={{ marginTop: ".5rem" }}>
							<Typography
								variant="overline"
								align="center"
								style={{ userSelect: "none" }}
							>
								{modeLabel}
							</Typography>
						</div>
					</div>
				)}

				{/* main label */}
				<div className={classes.labelContainer} style={vcStyle}>
					<div className={classes.valueLabel}>
						{/* When dragging show setpoint value */}
						{Boolean(setpointValue && handleChange) && (
							<Zoom
								in={Boolean(dragging && handleChange)}
								style={{ position: "absolute", userSelect: "none" }}
							>
								<Typography align="center" style={vlStyle}>
									{String(setpointValue) + "°"}
								</Typography>
							</Zoom>
						)}

						{/* Show display value by default */}
						{Boolean(displayValue) && (
							<Zoom
								in={!Boolean(dragging && handleChange)}
								style={{ userSelect: "none" }}
							>
								<Typography align="center" style={vlStyle}>
									{String(displayValue) + "°"}
								</Typography>
							</Zoom>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
