import * as React from "react";

// Redux
import { useAppSelector } from "../../../app/hooks";

// Interfaces
import { IFavoriteSpace, IPoint } from "../../../interfaces";
import { RootState } from "../../../app/store";
import { find, isEmpty } from "lodash";

// MUI
import {
	Button,
	createStyles,
	Grid,
	List,
	LinearProgress,
	makeStyles,
	Theme,
	Typography,
	CircularProgress,
	IconButton,
	Divider,
} from "@material-ui/core";

// Icons
import { Check, Star } from "@material-ui/icons";
import { ReactComponent as PM25 } from "../../../static/svg/pm25.svg";
import { ReactComponent as CO2 } from "../../../static/svg/c02.svg";
import { ReactComponent as Humidity } from "../../../static/svg/humidity.svg";
import { ReactComponent as Tvocs } from "../../../static/svg/tvocs.svg";

// Components
import { GlassDial } from "../../GlassDial/GlassDial";
import { GlassPaper } from "../../GlassPaper/GlassPaper";
import { Skeleton } from "@material-ui/lab";
import { useHistory } from "react-router";
import clsx from "clsx";
import { IFavoriteDataPayload } from "../../FavoriteListItem/FavoriteListItem";
import { yellow } from "@material-ui/core/colors";
import { useFirebase, useFirebaseConnect } from "react-redux-firebase";
import { useSnackbar } from "notistack";

// Styles
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		// App Bar
		favoriteButtonContainer: {
			zIndex: 99,
			position: "absolute",
			right: 0,
			top: 0,
		},
		secondary: {
			position: "absolute",
			left: 0,
			paddingLeft: "1rem",
		},
		componentWrapper: {
			display: "flex",
			paddingLeft: "1rem",
		},
		iconWrapper: {
			padding: "1rem 0",
			display: "flex",
			flexWrap: "wrap",
			alignItems: "center",
			justifyContent: "center",
			flexDirection: "column",
		},
		displayStringWrapper: {
			display: "flex",
			alignItems: "center",
			fontSize: "3rem",
		},
		// Favorite
		favorite: {
			color: yellow[500],
			stroke: "rgba(0,0,0,.25)",
		},
		componentTitle: {
			fontSize: "1.5rem",
		},
		textData: {
			fontSize: "2rem",
			fontWeight: 700,
		},
	})
);

interface IIAQData {
	id: string;
	name: string;
	points: IPoint | any[];
	virtualProfile: string;
}

interface IAirQualityContainerProps {
	iaqDevice: IIAQData;
}

export const IAQDeviceDial: React.FC<IAirQualityContainerProps> = ({
	iaqDevice,
}) => {
	const classes = useStyles();
	const history = useHistory();
	const rrf = useFirebase();
	const snackbar = useSnackbar();

	// Favorite Spaces: Redux
	const uid = useAppSelector((state: any) => state.firebase?.auth?.uid);
	const control = useAppSelector(
		(state: any) => state.firebase.ordered?.users?.[uid]?.control
	);

	// Favorite Spaces: Favorite Devices
	const favoriteDevicesFetch = Array.isArray(control)
		? find(control, ["key", "favoriteDevices"]) || { key: "", value: {} }
		: { key: "", value: {} };
	const favoriteDevices = Object.keys(favoriteDevicesFetch.value).map(
		(key: string) => ({ key, ...favoriteDevicesFetch.value[key] })
	);

	// Redux device data
	const deviceStore = useAppSelector((state: RootState) => state.devices); //devices fetched and stored on selection
	const deviceData = deviceStore.data?.[iaqDevice.id];

	// ** CONTROL POINT: If no device points, return null. Everything below assumes deviceData !== void 0
	if (Boolean(!deviceData) || Boolean(!deviceData.points?.length)) {
		return null;
	}

	// Convenience
	const { buildingId, floorId, spaceId, id } = deviceData;
	const favoriteDevice = find(favoriteDevices, ["deviceId", id]);

	// IAQ Device points
	const score = find(deviceData.points, [
		"virtualDeviceSlot",
		"airQualityScore",
	]);
	const co2 = find(deviceData.points, ["virtualDeviceSlot", "co2"]);
	const humidity = find(deviceData.points, ["virtualDeviceSlot", "humidity"]);
	const pm25 = find(deviceData.points, ["virtualDeviceSlot", "pm25"]);
	const voc = find(deviceData.points, ["virtualDeviceSlot", "voc"]);

	// TODO: Description doesn't have a virtualDeviceSlot
	const description =
		find(deviceData.points, ["virtualDeviceSlot", "description"]) ||
		find(deviceData.points, ["name", "air Quality Description Control Point"]);

	// ** CONTROL POINT: If no score.id (IAQ is without it's number component, return null;)
	if (!Boolean(score.id)) return null;

	return (
		<div>
			{/* Score Dial */}
			<Grid container style={{ padding: "1rem 0" }}>
				<Grid
					item
					xs={12}
					style={{ display: "flex", justifyContent: "center" }}
				>
					<GlassDial
						id={score.id}
						value={Math.round(Number(score.data.value))}
						label={description ? description.data.value : "IAQ"}
						size={250}
						fontSize={"4rem"}
						colorByValue
					/>
				</Grid>
			</Grid>
		</div>
	);
};
