import * as React from "react";

// MUI
import { makeStyles, Theme, Paper, Slider, Button } from "@material-ui/core";
// Colors
import { deepOrange, lightBlue, lightGreen } from "@material-ui/core/colors";
// Components
import { Dial } from "./Dial";

// Styles
const useStyles = makeStyles((theme: Theme) => ({
	dialWrapper: {
		width: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		margin: "1rem",
		padding: "1rem",
	},
	activeMode: {
		background: lightBlue[500],
	},
	activeStatus: {
		background: lightGreen[500],
	},
}));

interface ITemperatureDialProps {
	mode?: string;
	status?: string;
	displayValue?: string;
	setpointValue?: string;
}

const min = 65;
const max = 90;

export const TemperatureDial: React.FC<ITemperatureDialProps> = (props) => {
	const classes = useStyles();
	const [windowSize, setWindowSize] = React.useState<any>();
	const [dynamicSize, setDynamicSize] = React.useState(200);
	const parentRef = React.useRef<HTMLDivElement>(null);

	React.useLayoutEffect(() => {
		function updateSize() {
			setWindowSize([window.innerWidth, window.innerHeight]);
		}

		window.addEventListener("resize", updateSize);
		return () => window.removeEventListener("resize", updateSize);
	}, []);

	React.useEffect(() => {
		if (parentRef?.current) {
			let parentHeight = parentRef.current.offsetHeight;
			let parentWidth = parentRef.current.offsetWidth;

			setDynamicSize(Math.min(Math.min(parentHeight, parentWidth) / 2, 250));
		}
	}, [windowSize, parentRef]);

	// Demo
	const [mode, setMode] = React.useState("idle");
	const [status, setStatus] = React.useState("unoccupied");
	const [displayValue, setDisplayValue] = React.useState(75);
	const [setpointValue, setSetpointValue] = React.useState(75);

	const handleSetpointChange = (val: number) => {
		setSetpointValue(val);
	};

	const displayValueChange = (event: any, newValue: number | number[]) => {
		setDisplayValue(newValue as number);
	};

	React.useEffect(() => {
		if (displayValue < setpointValue) {
			setMode("heating");
		} else if (displayValue > setpointValue) {
			setMode("cooling");
		} else {
			setMode("idle");
		}
	}, [displayValue, setpointValue]);

	const color =
		mode === "idle"
			? ""
			: mode === "cooling"
			? lightBlue[500]
			: deepOrange[500];

	return (
		<div style={{ display: "flex", flexWrap: "wrap", padding: "1rem" }}>
			{/* DEMO */}
			<Slider
				min={min}
				max={max}
				value={displayValue}
				onChange={displayValueChange}
				aria-labelledby="continuous-slider"
			/>

			{/* Mode */}
			<div
				style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
			>
				<Button
					color={mode === "cooling" ? "primary" : "default"}
					onClick={() => setMode("cooling")}
					variant="contained"
					style={{ margin: ".5rem" }}
				>
					Cooling
				</Button>
				<Button
					color={mode === "idle" ? "primary" : "default"}
					onClick={() => setMode("idle")}
					variant="contained"
					style={{ margin: ".5rem" }}
				>
					Idle
				</Button>
				<Button
					color={mode === "heating" ? "primary" : "default"}
					onClick={() => setMode("heating")}
					variant="contained"
					style={{ margin: ".5rem" }}
				>
					Heating
				</Button>
			</div>

			{/* Status */}
			<div
				style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
			>
				<Button
					color={status === "unoccupied" ? "secondary" : "default"}
					onClick={() => setStatus("unoccupied")}
					variant="contained"
					style={{ margin: ".5rem" }}
				>
					Unoccupied
				</Button>
				<Button
					color={status === "occupied" ? "secondary" : "default"}
					onClick={() => setStatus("occupied")}
					variant="contained"
					style={{ margin: ".5rem" }}
				>
					Occupied
				</Button>
			</div>
			{/* End Demo */}

			<Paper
				ref={parentRef}
				style={{
					width: "100%",
					padding: dynamicSize / 5,
					display: "flex",
					justifyContent: "center",
				}}
			>
				<div className={classes.dialWrapper}>
					<Dial
						size={dynamicSize}
						min={min}
						max={max}
						numTicks={Number(max - min)}
						degrees={180}
						displayValue={displayValue}
						setpointValue={setpointValue}
						color={color}
						showLabels={true}
						statusLabel={status}
						modeLabel={mode}
						disabled={status === "occupied"}
						handleChange={handleSetpointChange}
					/>
				</div>
			</Paper>
		</div>
	);
};
