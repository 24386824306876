import React, { useCallback } from "react";

// Styles
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

// Mui
import {
	AppBar,
	Toolbar,
	IconButton,
	SwipeableDrawer,
	List,
	Divider,
	ListItem,
	ListItemIcon,
	ListItemText,
} from "@material-ui/core";

// Icons
import { Menu, PowerOff, VerifiedUserOutlined } from "@material-ui/icons";

// Firebase
import { useFirebase } from "react-redux-firebase";
import { useHistory } from "react-router";
import { GlassPaper } from "../../GlassPaper/GlassPaper";
import { DashboardAppBarBottom } from "./DashboardAppBarBottom";

// Styles
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1,
		},
		menuButton: {
			marginRight: theme.spacing(2),
		},
		title: {
			flexGrow: 1,
		},
	})
);
interface IDashboardAppBarProps {
	hide?: boolean;
}

export const DashboardAppBar: any = (props: any) => {
	const classes = useStyles();
	const { children, hide } = props;

	// Hooks
	const firebase = useFirebase();
	const history = useHistory();

	// Menu State
	const [open, setOpen] = React.useState(false);

	// Handlers
	const toggleDrawer = useCallback(
		(open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
			if (
				event &&
				event.type === "keydown" &&
				((event as React.KeyboardEvent).key === "Tab" ||
					(event as React.KeyboardEvent).key === "Shift")
			) {
				return;
			}

			setOpen(open);
		},
		[]
	);

	// Menu Handlers
	const handleLogout = () => {
		firebase.logout();
	};

	// Menu Options
	const menuOptions = (toggleDrawer: any) => (
		<div
			style={{ width: 250 }}
			role="presentation"
			onClick={toggleDrawer(false)}
			onKeyDown={toggleDrawer(false)}
		>
			<List>
				<ListItem button onClick={() => history.push("/profile")}>
					<ListItemIcon>
						<VerifiedUserOutlined />
					</ListItemIcon>
					<ListItemText primary={"Profile"} />
				</ListItem>
			</List>
			<Divider />
			<List>
				<ListItem button onClick={() => handleLogout()}>
					<ListItemIcon>
						<PowerOff />
					</ListItemIcon>
					<ListItemText primary={"Log Out"} />
				</ListItem>
			</List>
		</div>
	);

	// Options (hide explicitly through the router or control through the URL bar)
	if (hide || history?.location.search?.includes("hideToolbar")) {
		return children;
	}

	return (
		<React.Fragment>
			<GlassPaper square>
				{/* Appbar */}
				<AppBar position="static" color="transparent">
					<Toolbar>
						{/* Offset Right */}
						<span className={classes.title} />
						<IconButton
							edge="start"
							className={classes.menuButton}
							color="inherit"
							aria-label="menu"
							onClick={toggleDrawer(!open)}
						>
							<Menu />
						</IconButton>
					</Toolbar>
				</AppBar>

				{/* Menu */}
				<React.Fragment>
					<SwipeableDrawer
						anchor={"right"}
						open={open}
						onClose={toggleDrawer(false)}
						onOpen={toggleDrawer(true)}
					>
						{menuOptions(toggleDrawer)}
					</SwipeableDrawer>
				</React.Fragment>
			</GlassPaper>

			{/* Routes */}
			{props.children}

			{/* Bottom Appbar */}
			<DashboardAppBarBottom />
		</React.Fragment>
	);
};
