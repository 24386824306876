// Redux Toolkit
import {
	configureStore,
	ThunkAction,
	Action,
	combineReducers,
} from "@reduxjs/toolkit";

// Firebase
import firebase from "firebase/app";
import { createFirestoreInstance, firestoreReducer } from "redux-firestore";
import { firebaseReducer } from "react-redux-firebase";

//Middleware: Logger
import logger from "redux-logger";

//Middleware: Router
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";

// Reducers
import appStateReducer from "../features/appState/appStateSlice";
import buildingsReducer from "../features/buildings/buildingsSlice";
import clientsReducer from "../features/clients/clientsSlice";
import devicesReducer from "../features/devices/devicesSlice";
import historyReducer from "../features/history/historySlice";
import weatherReducer from "../features/weather/weatherSlice";

// Configure RRF
export const rrfConfig = {
	userProfile: "users",
	fileMetadataFactory: (
		uploadRes: { metadata: { name: string; fullPath: string } },
		firebase: any,
		metadata: { name: string; fullPath: string },
		downloadURL: string
	) => {
		// Generate URL, path, and name for all files uploaded to storage;
		// Store in DB
		const {
			metadata: { name, fullPath },
		} = uploadRes;

		return {
			name,
			fullPath,
			downloadURL,
		};
	},
};

export const history = createBrowserHistory();

export const store = configureStore({
	reducer: combineReducers({
		appState: appStateReducer,
		buildings: buildingsReducer,
		clients: clientsReducer,
		devices: devicesReducer,
		firebase: firebaseReducer,
		firestore: firestoreReducer,
		history: historyReducer,
		router: connectRouter(history),
		weather: weatherReducer,
	}),
	middleware: (getDefaultMiddleware) =>
		process.env.NODE_ENV === "development"
			? getDefaultMiddleware({ serializableCheck: false })
					.prepend(routerMiddleware(history))
					.concat(logger)
			: getDefaultMiddleware({ serializableCheck: false }).prepend(
					routerMiddleware(history)
			  ),
});

export const rrfProps = {
	firebase,
	config: rrfConfig,
	dispatch: store.dispatch,
	createFirestoreInstance,
};

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;
