import * as React from "react";

// Mui
import {
	Box,
	CircularProgress,
	Typography,
	IconButton,
	// Styles
	createStyles,
	makeStyles,
	lighten,
	Theme,
} from "@material-ui/core";

// Colors
import {
	amber,
	deepOrange,
	green,
	lightGreen,
	red,
} from "@material-ui/core/colors";

// Icons
import { Refresh } from "@material-ui/icons";

// Util
import clsx from "clsx";

// Styles
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		// App Bar
		glass: {
			display: "inline-flex",
			backgroundColor: "transparent",
			backgroundImage:
				"linear-gradient(to bottom right, rgba(255, 255, 255, .2), rgba(255, 255, 255, 0))",
			backdropFilter: "blur(7px)",
			boxShadow: "10px 10px 10px rgba(30, 30, 30, .1)",
			borderRadius: "100%",
		},
		fullWidth: {
			width: "100%",
		},
		round: {},
		svgCircle: {
			stroke: (props: any) => `url(#${props.id})`,
			strokeLinecap: "round",
		},
		shadows: {
			boxShadow:
				"inset -3px -3px 10px -10px rgba(255, 255, 255, 1), inset 0px 0px 10px rgba(13, 39, 80, .3)",
			filter:
				"drop-shadow(3px 3px 10px rgba(13, 39, 80, .5)) drop-shadow(-2px -2px 4px rgba(255, 255, 255, 1))",
		},
	})
);

// If min/max provided, normalize range
const normalize = (value: number, min: number, max: number) =>
	((value - min) * 100) / (max - min);

interface IGlassDial {
	id: any;
	value: number | string;
	appendToValue?: string;
	label?: string;
	min?: number;
	max?: number;
	color?: string;
	colorByValue?: boolean;
	size?: number;
	style?: any;
	className?: any;
	mobile?: boolean;
	fontSize?: string | number;
}

export const GlassDial: React.FC<IGlassDial> = (props) => {
	// Props
	const {
		id,
		value,
		appendToValue,
		label,
		color = "",
		colorByValue = false,
		size,
		min = 0,
		max = 100,
		mobile,
		fontSize = "2rem",
		...rest
	} = props;

	const classes = useStyles({ id });

	// Create default gradient
	let colorStart = "#00bc9b";
	let colorEnd = "#5eaefd";

	// Tune color if given
	if (color) {
		colorStart = color;
		colorEnd = lighten(color, 0.3);
	}

	// Use preset colors by value
	if (colorByValue) {
		// Good
		if (value >= 90) {
			colorStart = green[500];
			colorEnd = lighten(colorStart, 0.3);
		}
		// Okay
		if (value < 90 && value >= 80) {
			colorStart = lightGreen[600];
			colorEnd = lighten(colorStart, 0.3);
		}

		// Warn
		if (value < 80 && value > 60) {
			colorStart = amber[700];
			colorEnd = lighten(colorStart, 0.3);
		}
		// Danger
		if (value <= 60 && value > 20) {
			colorStart = deepOrange[500];
			colorEnd = lighten(colorStart, 0.3);
		}

		if (value <= 20) {
			colorStart = red[500];
			colorEnd = lighten(colorStart, 0.3);
		}
	}

	let normalizedValue;
	if (min && max && typeof value === "number") {
		if (!Boolean(isNaN(Number(value)))) {
			normalizedValue = normalize(value, min, max);
		}
	}

	return (
		<div
			className={clsx(classes.glass)}
			style={{ padding: mobile ? 0 : "1rem" }}
		>
			<Box position="relative" display="inline-flex">
				{/* Load gradient in SVG */}
				<svg width={1} height={1} style={{ position: "absolute" }}>
					<linearGradient id={id} x1="100%" y1="50%" x2="0%" y2="50%">
						<stop offset="0%" stopColor={colorStart} />
						<stop offset="100%" stopColor={colorEnd} />
					</linearGradient>
				</svg>

				{/* Progress with glass, shadows, and ability to normalize value */}
				<CircularProgress
					size={size ? size : 100}
					variant="static"
					thickness={2}
					value={
						normalizedValue
							? normalizedValue
							: typeof value === "number"
							? value
							: 0
					}
					className={clsx(classes.glass, classes.shadows)}
					classes={{ circle: classes.svgCircle }}
				/>

				{/* Value display and label */}
				<Box
					top={0}
					left={0}
					bottom={0}
					right={0}
					position="absolute"
					display="flex"
					flexWrap="wrap"
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
				>
					{/* Value */}
					<Typography
						style={{ fontSize, lineHeight: 1 }}
						variant="caption"
						component="div"
						color="textSecondary"
					>
						{typeof value === "number" && !Boolean(isNaN(value)) ? (
							`${Math.round(value)}`
						) : (
							<IconButton onClick={() => window.location.reload(false)}>
								<Refresh />
							</IconButton>
						)}
						{Boolean(appendToValue) && appendToValue}
					</Typography>

					{/* If Label, show */}
					{Boolean(label) && (
						<Typography
							variant="overline"
							component="div"
							color="textSecondary"
						>
							{label}
						</Typography>
					)}
				</Box>
			</Box>
		</div>
	);
};
