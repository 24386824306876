import * as React from "react";

// Redux
import { useAppSelector } from "../../../app/hooks";

// Interfaces
import { IPoint } from "../../../interfaces";
import { RootState } from "../../../app/store";
import { find, isEmpty } from "lodash";

// MUI
import {
	Button,
	createStyles,
	Grid,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	makeStyles,
	Theme,
	Typography,
} from "@material-ui/core";

// Icons
import { Check } from "@material-ui/icons";
import { ReactComponent as PM25 } from "../../../static/svg/pm25.svg";
import { ReactComponent as CO2 } from "../../../static/svg/c02.svg";
import { ReactComponent as Humidity } from "../../../static/svg/humidity.svg";
import { ReactComponent as Tvocs } from "../../../static/svg/tvocs.svg";

// Components
import { GlassDial } from "../../GlassDial/GlassDial";
import { GlassPaper } from "../../GlassPaper/GlassPaper";
import { Skeleton } from "@material-ui/lab";
import { useHistory } from "react-router";
import { TemperatureDial } from "../../TemperatureDial/TemperatureDial";

// Styles
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		// App Bar
		primary: {},
		secondary: {
			position: "absolute",
			left: 0,
			paddingLeft: "1rem",
		},
		componentWrapper: {
			display: "flex",
			marginLeft: "1rem",
		},
		iconWrapper: {
			display: "flex",
			flexWrap: "wrap",
			alignItems: "center",
			justifyContent: "center",
		},
		displayStringWrapper: {
			display: "flex",
			alignItems: "center",
		},
	})
);

interface IThermostatProps {
	id: string;
	name: string;
	points: IPoint | any[];
	virtualProfile: string;
}

interface IThermostatContainerProps {
	thermostatDevice: IThermostatProps;
}

export const Thermostat: React.FC<IThermostatContainerProps> = ({
	thermostatDevice,
}) => {
	const classes = useStyles();
	const history = useHistory();

	// Building data
	const deviceStore = useAppSelector((state: RootState) => state.devices); //devices fetched and stored on selection
	const deviceData = deviceStore.data?.[thermostatDevice.id];

	// If no device data or the device has no points, return null;
	if (Boolean(!deviceData || !deviceData?.points?.length)) {
		return null;
	}

	console.log(deviceData);

	if (!deviceData) return null;

	const { buildingId, floorId, spaceId, id } = deviceData;
	console.log(deviceData);

	return (
		<GlassPaper style={{ padding: "1rem" }} square>
			<Grid container>
				<Grid item xs={12}>
					<TemperatureDial />
				</Grid>
			</Grid>
		</GlassPaper>
	);
};
