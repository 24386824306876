import * as React from "react";

// MUI
import {
	Container,
	Divider,
	Grid,
	LinearProgress,
	Theme,
	createStyles,
	makeStyles,
	useMediaQuery,
	useTheme,
	Typography,
} from "@material-ui/core";

// Lab
import { Skeleton } from "@material-ui/lab";

// Router
import { useHistory, useLocation, useParams } from "react-router";

// Redux
import { fetchBuilding } from "../../features/buildings/buildingsSlice";
import { useAppSelector } from "../../app/hooks";
import { useDispatch } from "react-redux";

// Interfaces
import { IDevice } from "../../interfaces";
import { RootState } from "../../app/store";

// DB
import { useFirebaseConnect } from "react-redux-firebase";

// Util
import { find, isEmpty, uniq } from "lodash";

// Components
import { SpaceAppBarBottom } from "../../components/AppBars/SpaceAppBar/SpaceAppBarBottom";
import { GlassPaper } from "../../components/GlassPaper/GlassPaper";
import { GlassDial } from "../../components/GlassDial/GlassDial";
import { Weather } from "../../components/Weather/Weather";

// Widgets
import { DeviceLoader } from "../../components/DeviceWidgets/DeviceLoader/DeviceLoader";
import { IAQCard } from "../../components/DeviceWidgets/IAQ/IAQCard";
import { Thermostat } from "../../components/DeviceWidgets/Thermostat/Thermostat";
import { grey } from "@material-ui/core/colors";

interface ISpaceProps {}

// Styles
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		// App Bar
		wrapper: {
			padding: "1rem",
			marginTop: "1rem",
		},
		container: {
			padding: "1rem",
			width: "100%",
		},
		headerButtonWrapper: {
			display: "flex",
			justifyContent: "flex-end",
			padding: "1rem",
		},
		// Generic
		spacer: {
			width: "100%",
			marginTop: "1rem",
		},
		flex: {
			display: "flex",
			flexWrap: "wrap",
			alignItems: "baseline",
		},
		fullWidth: {
			width: "100%",
		},
		spaceBetween: {
			justifyContent: "space-between",
		},
	})
);

export const Space: React.FC<ISpaceProps> = (props) => {
	const { buildingId, floorId, spaceId }: any = useParams();

	// HOOKS
	// Router
	const history = useHistory();
	const { pathname, search } = useLocation();

	// Styles
	const classes = useStyles();
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down("sm"));

	// Redux
	const dispatch = useDispatch();

	// STORE
	// Firebase
	const api = useAppSelector((state: RootState) => state.firebase.profile.api);
	const uid = useAppSelector((state: any) => state.firebase?.auth?.uid);
	useFirebaseConnect([`users/${uid}/control`]);

	// Building data
	const buildingStore = useAppSelector((state: RootState) => state.buildings); //buildings fetched and stored on selection
	const buildingData = buildingStore.data?.[buildingId];

	// If no buildingData, load it into the store
	React.useEffect(() => {
		if (!Boolean(buildingData) && buildingStore.status !== "loading") {
			dispatch(fetchBuilding({ api, id: buildingId }));
		}
	}, []);

	// If building data exists, get all associated data with spaces
	const building = buildingStore.data?.[buildingId] || {
		floors: [],
	};
	const floor = find(building.floors, ["id", floorId]) || {
		spaces: [],
	};
	const space = find(floor.spaces, ["id", spaceId]);

	// Parse out devices from space
	const thermostatDevices =
		space?.devices.filter((device: IDevice) => {
			return device.virtualProfile === "virtualThermostat";
		}) || [];

	const lightingDevices =
		space?.devices.filter((device: IDevice) => {
			return device.virtualProfile === "virtualLightSwitch";
		}) || [];

	const airQualityDevices =
		space?.devices.filter((device: IDevice) => {
			return device.virtualProfile === "virtualAirQuality";
		}) || [];

	// Get unique device types to dynamically load icons on appbar
	const deviceTypes = uniq(
		space?.devices.map((device: any) => device.virtualProfile)
	);

	// Convenience ob to check for existence of device
	const hasDevice = {
		access: Boolean(deviceTypes.includes("virtualAccess")),
		blinds: Boolean(deviceTypes.includes("virtualBlinds")),
		iaq: Boolean(deviceTypes.includes("virtualAirQuality")),
		lights: Boolean(deviceTypes.includes("virtualLightSwitch")),
		schedule: Boolean(deviceTypes.includes("virtualSchedule")),
		thermostat: Boolean(deviceTypes.includes("virtualThermostat")),
	};

	// If no devices are currently selected, default load one in order
	if (!search) {
		if (hasDevice.iaq) {
			history.push({
				pathname: pathname,
				search: "?component=iaq",
			});
		} else if (hasDevice.thermostat) {
			history.push({
				pathname: pathname,
				search: "?component=thermostat",
			});
		} else if (hasDevice.lights) {
			history.push({
				pathname: pathname,
				search: "?component=lights",
			});
		}
	}

	// Convenience ob to check selected component
	const selection = {
		access: Boolean(search.includes("access")),
		blinds: Boolean(search.includes("blinds")),
		iaq: Boolean(search.includes("iaq")),
		lights: Boolean(search.includes("lights")),
		schedule: Boolean(search.includes("schedule")),
		thermostat: Boolean(search.includes("thermostat")),
	};

	return (
		<>
			{Boolean(!space || buildingStore.status === "loading") ? (
				<LinearProgress style={{ width: "100%" }} />
			) : (
				<div style={{ display: "flex", flexWrap: "wrap", padding: 0 }}>
					{/* Metadata Components */}
					<div
						style={{
							display: "flex",
							width: "100%",
							justifyContent: "space-evenly",
						}}
					>
						<GlassPaper square style={{ width: "100%" }}>
							<Grid container>
								{/* Header */}
								<Grid item xs={12}>
									<Typography
										variant="h5"
										align="center"
										style={{ padding: "1rem", fontWeight: 700 }}
									>
										{space.name}
									</Typography>
								</Grid>

								<Divider style={{ width: "100%" }} />

								<Grid item xs={12} sm={7}>
									{/* If there's metadata, show it */}

									<div
										style={{
											display: "flex",
											flexWrap: "wrap",
											justifyContent: "space-evenly",
											alignItems: "center",
											height: "100%",
											padding: "1rem 0",
										}}
									>
										{/* Air Quality Dial */}
										{Boolean(
											space.metadata?.airQuality &&
												!isEmpty(space.metadata?.airQuality)
										) ? (
											<GlassDial
												id={"aq"}
												value={Number(space.metadata?.airQuality.avg)}
												label={`Air Quality`}
												size={150}
												colorByValue
												mobile={mobile}
											/>
										) : (
											<GlassDial
												id={"no_airquality"}
												value={"N/A"}
												label={`No IAQ Metadata`}
												size={150}
												color={grey[500]}
												mobile={mobile}
											/>
										)}

										{/* Temperature Dial */}
										{Boolean(
											space.metadata?.temperature &&
												!isEmpty(space.metadata?.temperature)
										) ? (
											<GlassDial
												id={"temp"}
												value={Number(space.metadata?.temperature.avg)}
												label={`Temperature`}
												size={150}
												appendToValue="°"
												mobile={mobile}
											/>
										) : (
											<GlassDial
												id={"no_temp"}
												value={"N/A"}
												label={`No Temp Metadata`}
												size={150}
												color={grey[500]}
												mobile={mobile}
											/>
										)}
									</div>
								</Grid>

								<Grid item xs={12} sm={5}>
									{Boolean(buildingData) && (
										<Weather
											square
											backgroundImage={false}
											address={`${buildingData.address}, ${buildingData.city}, ${buildingData.state}, ${buildingData.zip}`}
											city={buildingData.city}
											id={buildingData.id}
										/>
									)}
								</Grid>
							</Grid>
						</GlassPaper>
					</div>
					<Divider style={{ width: "100%" }} />

					{/* Components */}
					<Grid container>
						{/* Air Quality */}
						{Boolean(selection.iaq) &&
							airQualityDevices.map((iaq: any) => (
								<Grid item xs={12} sm={4} key={iaq.id}>
									<DeviceLoader
										device={iaq}
										buildingId={building.id}
										floorId={floor.id}
										spaceId={space.id}
									>
										<IAQCard iaqDevice={iaq} />
									</DeviceLoader>
								</Grid>
							))}

						{/* Thermostat */}
						{thermostatDevices.map((thermostat: any) => (
							<Grid item xs={12} sm={4} key={thermostat.id}>
								<DeviceLoader
									device={thermostat}
									buildingId={building.id}
									floorId={floor.id}
									spaceId={space.id}
								>
									<Thermostat thermostatDevice={thermostat} />
								</DeviceLoader>
							</Grid>
						))}
					</Grid>
				</div>
			)}

			{/* Bottom Appbar */}
			{buildingStore.status === "loading" ? (
				<Skeleton
					height={120}
					width={"100%"}
					style={{ position: "absolute", bottom: 70, left: 0 }}
				/>
			) : (
				<SpaceAppBarBottom
					space={space}
					iaq={hasDevice.iaq}
					lights={hasDevice.lights}
					blinds={hasDevice.blinds}
					thermostat={hasDevice.thermostat}
				/>
			)}
		</>
	);
};
